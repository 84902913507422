<template>
  <div class="card">
    <div class="card-header border-0">
      <h3 class="mb-0 text-left">Dashboard</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Total Events
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{ getEvents.count }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gradient-red text-white rounded-circle shadow"
                  >
                    <i class="ni ni-active-40"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Total Participants
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{
                    getParticipants.count
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow"
                  >
                    <i class="ni ni-chart-pie-35"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Total Pages
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{
                    getPages.count
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gradient-green text-white rounded-circle shadow"
                  >
                    <i class="ni ni-money-coins"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Total FAQ's
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{
                    getFaq.count
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gradient-info text-white rounded-circle shadow"
                  >
                    <i class="ni ni-chart-bar-32"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <h3 class="mb-0">
                  Events
                  <span style="font-size:12px;"
                    >(<router-link :to="{ name: 'AdminEventsList' }"
                      >View All</router-link
                    >)</span
                  >
                </h3>
              </div>
            </div>
            <div class="table-responsive">
              <!-- Projects table -->
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">ROUTE</th>
                    <th scope="col">Min WEIGHT</th>
                    <th scope="col">DURATION</th>
                    <th scope="col">Participants</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(event, i) in getEvents.data" :key="i">
                    <th scope="row">
                      {{ event.name.substring(0, 20) }}
                    </th>
                    <td>
                      {{ getRoute(event.route) }}
                    </td>
                    <td>{{ event.minimum_weight }} KGs</td>
                    <td>{{ event.total_duration }} Min</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'AdminEventsParticipants',
                          params: { id: event._id },
                        }"
                        >{{ event.ParticipantsCount }}</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header border-0">
              <h3 class="mb-0">
                New Users
                <span style="font-size:12px;"
                  >(<router-link :to="{ name: 'AdminPlayersList' }"
                    >View All</router-link
                  >)</span
                >
              </h3>
            </div>
            <div class="table-responsive">
              <!-- Projects table -->
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Weight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(player, i) in getParticipants.data" :key="i">
                    <th scope="row">
                      {{ player.firstName }}
                    </th>
                    <td>
                      {{ player.lastName }}
                    </td>
                    <td>{{ player.email.substring(0, 30) }}</td>
                    <td>
                      {{
                        player.gender && player.gender === 0 ? "Male" : "Female"
                      }}
                    </td>
                    <td>{{ player.weight }} kg</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminDashboard",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("dashboard", [
      "getEventData",
      "getParticipantData",
      "getFaqData",
      "getPageData",
    ]),
    ...mapGetters("routes", ["getRoutes"]),

    getEvents() {
      if (!this.getEventData) return [];
      return this.getEventData;
    },
    getParticipants() {
      if (!this.getParticipantData) return [];
      return this.getParticipantData;
    },
    getFaq() {
      if (!this.getFaqData) return [];
      return this.getFaqData;
    },
    getPages() {
      if (!this.getPageData) return [];
      return this.getPageData;
    },
  },
  async mounted() {
    await this.$store.dispatch("dashboard/getAll");

    if(this.getRoutes.length === 0) {
      await this.$store.dispatch("routes/loadRoutes");
    }
  },
  methods: {
    getGender(g) {
      const genders = { 0: "Male", 1: "Female", 2: "Both" };
      return genders[g];
    },

    getRoute(r) {
      const route = this.getRoutes.find(
        (route) => parseInt(route.value) === parseInt(r)
      );
      return route?.label;
    },
  },
};
</script>
